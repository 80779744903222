<template>
    <CModal
      :title="title"
      :color="color"
      size="lg"
      :show.sync="userModal"
    >   
        <CForm>
            
            <CRow>
              <CCol md="12">
                <CInput
                  label="Nombre:"
                  :lazy="false"
                  :value.sync="$v.form.name.$model"
                  :isValid="checkIfValid('name')"
                  placeholder=""
                  autocomplete="given-name"
                  invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CInput
                  label="Puesto de Trabajo:"
                  :lazy="false"
                  :value.sync="$v.form.job_position.$model"
                  :isValid="checkIfValid('job_position')"
                  placeholder=""
                  autocomplete="given-job_position"
                  invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
                />
              </CCol>
              <CCol md="6">
                <CInput
                  label="Whatsapp:"
                  :lazy="false"
                  :value.sync="$v.form.phone.$model"
                  :isValid="checkIfValid('phone')"
                  placeholder=""
                  autocomplete="given-phone"
                  invalidFeedback="Este campo debe tener un número de Whatsapp de 10 dígitos."
                />
              </CCol>
            </CRow>
        </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton color="danger" @click="resetModal()" style="float: left;" v-if="form.id > 0">Resetear Contraseña</CButton>
          <CButton @click="store" :color="color">Aceptar</CButton>
        </template>
        <ResetCollaboratorModal ref="resetModal" @reset="resetProcess"></ResetCollaboratorModal>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, maxLength, email, sameAs, helpers } from "vuelidate/lib/validators"
import roles from '../../services/roles';
import ResetCollaboratorModal from '../collaborators/ResetCollaboratorModal.vue'

export default {
  name: 'CouncilModal',
  props: {
    permissions: Array
  },
  components: { ResetCollaboratorModal },
  data () {
    return {
      userModal: false,
      title: "Nuevo Miembro",
      color: "info",
      isEdit: false,
      form: this.getEmptyForm(),
      submitted: false,
      fields: [
        {key: 'name', label: 'Permisos del Acceso'},
        {key: 'actions', label: '', class: 'actions' }
      ]
    }
  },
  mounted: async function() {
      let response = await roles.get(); 

      if(response.type == "success"){
        this.roles = response.data;
      }

      this.rolesOptions = await this.$parseSelectOptionsOrdered(this.roles, "slug", "name");
  },
  methods: {
    storeModal () {
      this.userModal = true;
      this.color = "info";
      this.title = 'Nuevo Miembro';
      this.isEdit = false;
      this.submitted = false;

      this.form = {
          id: 0,
          name: '',
          phone: '',
          job_position: ''
      }
    },
    updateModal (item) {
      this.userModal = true;
      this.color = "warning";
      this.title = 'Editar Miembro';
      this.isEdit = true;
      this.submitted = false;

      this.form = {
          id: item.id,
          name: item.name,
          phone: item.phone,
          job_position: item.job_position,
      };
    },
    resetModal () {
      this.$refs.resetModal.showModal(this.form);
    },
    store () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        if(this.form.id > 0){
          this.$emit("update", this.form );
        }
        else{
          this.$emit("store", this.form );
        }

        this.closeModal();
      }
    },
    resetProcess(form){
      this.$emit("reset", form );

      this.closeModal();
    },
    closeModal () {
      this.userModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    getEmptyForm () {
      return {
          id: 0,
          name: '',
          phone: '',
          job_position: ''
      }
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid || field.$model === '')
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3)
      },
      phone: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(10)
      },
      job_position: {

      }
    }
  },
}
</script>